<template>
  <div>

    <router-view></router-view>
  </div>
</template>

<script>


export default {
  components: {  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 0px;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #e5e6e7;
}
body {
  overflow-x: hidden;
}
</style>
