<template>
  <div>
    <div class="row banner ">
      <img src="@/assets/images/Tema/space.jpg" style="height: 300px">
      <div class="imageonw">
        MİSTRAL
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="  about-us-w " style="display: flex; justify-content: center; font-weight: bold; font-size: 40px">
          Wir sind wer?
        </div>
        <div class="  about-us-w ">
          Als Anka Beschaffung Bildung und Beratung A.Ş. haben wir seit unserer Gründung unsere renommierte Position in der Türkei durch Dienstleistungen in verschiedenen Bereichen wie öffentliche Ausschreibungen, direkte Beschaffung, Einkauf und Import-Export gefestigt. Dank unserer innovativen Ansätze und unserem kontinuierlichen Verbesserungsansatz entwickeln wir maßgeschneiderte Lösungen für die Bedürfnisse unserer Kunden.

          Unter dem Namen Mistral GmbH haben wir den Schritt in den europäischen Markt gemacht. In dieser neuen Ära streben wir danach, unsere langjährige Erfahrung im Beschaffungsprozess und unser Fachwissen herauszustellen und durch die Integration neuer Technologien Lösungen zu entwickeln, die auch in Deutschland und anderen europäischen Ländern einen Unterschied machen. Mit nachhaltigem Erfolg und auf gegenseitigem Vertrauen basierend, streben wir danach, internationale Projekte zu realisieren, die unseren Namen auch international bekannt machen werden. Darüber hinaus tragen unsere Schulungs- und Beratungsdienste dazu bei, dass unsere Kunden und Geschäftspartner über branchenspezifische Entwicklungen informiert sind und ihre Kapazitäten erweitern können.

          Als Mistral GmbH werden wir unsere Präsenz in den Bereichen internationaler Handel und Geschäftsentwicklung mit innovativen und nachhaltigen Strategien weiter stärken und ausbauen. Wir werden unsere Partnerschaften und Netzwerke erweitern, um Wettbewerbsvorteile auf dem globalen Markt zu erlangen und unseren Kunden weiterhin erstklassigen Service bieten zu können.
        </div>
      </div>
      <div class="row  " style="margin-top:40px">
        <img src="@/assets/images/imgAnka/blackmistral.png">

      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="  about-us-w " style="display: flex; justify-content: center; font-weight: bold; font-size: 40px">
          Unsere Vision
        </div>
      </div>
      <div class="kapsa">
        <div class="row">

          <div class="col-lg-12">
            <!--            <div class="  about-us-w "-->
            <!--                 style="display: flex; justify-content: center; font-weight: bold; font-size: 40px">-->
            <!--              Lorem ipsum dolor-->
            <!--            </div>-->
            <div class="  about-us-w " style="margin-top: 10px;">


              Tabii, bu metni Almanca'ya çevirebilirim:

              Unser Unternehmen strebt danach, eine führende Rolle auf dem globalen Markt als nachhaltig wachsendes und exzellentes Unternehmen in der Lieferkette einzunehmen. Jeder unserer Mitarbeiter ist um eine gemeinsame Vision vereint und zielt darauf ab, Dienstleistungen anzubieten, die die Erwartungen unserer Stakeholder übertreffen. In diesem Sinne erneuern wir uns kontinuierlich, indem wir Fachkräfte ausbilden und unsere eigenen Technologien durch innovative Ansätze entwickeln.

              Durch strategische Partnerschaften und Kooperationen sind wir in der Lage, Brancheninnovationen zu verfolgen und umzusetzen. Wir handeln mit einem ethischen Geschäftsverständnis, das mit Prinzipien des Qualitätsmanagements und kontinuierlicher Verbesserung ausgestattet ist. Unter den globalen Wettbewerbsbedingungen produzieren wir Lösungen, die die Effizienz steigern und Kosten optimieren, indem wir technologische Entwicklungen genau verfolgen.

              Kundenzentriertheit ist für uns nicht nur ein Prinzip, sondern auch der Schlüssel zum nachhaltigen Erfolg. Aus diesem Grund bieten wir flexible und schnelle Lösungen an, um die Kundenzufriedenheit auf höchstem Niveau zu halten. Unser Unternehmen, das internationalen Standards entspricht, strebt danach, sich als Symbol für Qualität und Vertrauen in der Branche zu etablieren.

            </div>
          </div>
        </div>
      </div>
      <div class="row  " style="margin-top:40px">
        <img src="@/assets/images/imgAnka/whiteAnkaS.png">

      </div>
    </div>
  </div>
</template>


<script>

import {defineComponent} from 'vue';

export default defineComponent({
  name: 'componentPage',
  data() {
    return {
      showOverlay: false
    };
  },
  methods: {
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    }
  }
});
</script>

<style>
.kapsa {
  display: flex;
  margin-top: 5vh;

}

.about-us-w {
  font-size: 25px;
  line-height: 40px;
  margin-top: 50px;
  color: black;
  text-align: start;
  font-weight: 400
}

@media (max-width: 1191px) {
  .about-us-w {
    font-size: 17px;
    line-height: 40px;
    margin-top: 50px;
    color: black;
    text-align: start;
    font-weight: 400
  }
  .mobilResim{
    width:100%
  }
}

@media (min-width: 1192px) {
  .about-us-w {
    font-size: 25px;
    line-height: 40px;
    margin-top: 50px;
    color: black;
    text-align: start;
    font-weight: 400
  }

}
.imageonw {

  display: flex;
  justify-content: center;
  position: absolute;
  margin-top: 120px;
  font-family: SandrinaRegular, Sandrina;
  font-weight: bold;
  font-size: 80px;
  color: #ffffff;
}

.banner {


  width: 100%;
  background-color: transparent;


}

.baslik {

  color: black;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.misyon, .vizyon {
  width: 100%;
  max-width: 800px;
  text-align: justify; /* Yazıları ortalayarak düzgün bir şekilde görüntülemek için */
}

.misyon h2, .vizyon h2 {
  color: darkblue;
  font-size: 25px;
  margin-bottom: 10px; /* Başlıklar arasına biraz boşluk eklemek için */
}

.misyon p, .vizyon p {
  color: black;
  font-size: 15px;
  margin-bottom: 20px; /* Paragraflar arasına biraz boşluk eklemek için */
}

.image-overlay {
  position: relative;
  cursor: pointer;
  overflow: hidden; /* Arka plan resminin sınırlarını aşan kısımları gizlemek için */
}

.background-image {
  display: block;
  width: 100%;
  transition: opacity 0.3s ease; /* Geçiş efekti ekleyin */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  color: #fff;
  opacity: 0; /* Başlangıçta görünmez */
  transition: opacity 0.3s ease; /* Geçiş efekti ekleyin */
}

.overlay img {
  max-width: 100%;
  max-height: 100%;
}

.overlay-visible {
  opacity: 1; /* Hover olduğunda görünür hale getirin */
}

.image-overlay:hover .background-image {
  opacity: 10%; /* Hover olduğunda arka plan resmini tamamen görünür yapın */

}

.image-overlay:hover .overlay {
  opacity: 100; /* Hover olduğunda açılan resmi görünür yapın */
  background-color: #f5f5f5;
}

.h1 {
  background: linear-gradient(to right, #e3b871, #b00101);
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
  padding-top: 10rem;
  height: 35rem;

}

.kutu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resimkare {
  width: 100%;
  margin-top: 20px;
  border-radius: 20px;
  margin-left: 20px;
}

.yazıkare {
  margin-top: 5%;
  margin-right: 5%;

  color: black;
  text-align: justify;
}

.card {
  width: 300px;
  height: 200px;
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  background-color: #6A2C70;
  color: #fff;
  display: flex;
  align-items: center;
  border: 10px solid #6A2C70;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
}

.card-back {
  background-color: #F08A5D;
  color: #fff;
  display: flex;
  align-items: center;
  border: 10px solid #F08A5D;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(180deg);
}

</style>