<template>
  <nav class="openClose" style="margin-bottom:-40px"  role="navigation">
    <div id="menuToggle">
      <!--
      A fake / hidden checkbox is used as click reciever,
      so you can use the :checked selector on it.
      -->
      <input type="checkbox"/>

      <!--
      Some spans to act as a hamburger.

      They are acting like a real hamburger,
      not that McDonalds stuff.
      -->
      <span></span>
      <span></span>
      <span></span>

      <!--
      Too bad the menu has to be inside of the button
      but hey, it's pure CSS magic.
      -->
      <ul id="menu">
        <a @click="clickRouteMethod( '/')">
          <li>Startseite</li>
        </a>
        <a @click="clickRouteMethod( '/hakkimizda')">
          <li>Über uns</li>
        </a>
        <a @click="clickRouteMethod( '/contact')">
          <li>Kontakt</li>
        </a>
        <a @click="clickRouteMethod( '/hizmetlerimiz')">
          <li>Unsere Dienstleistungen</li>
        </a>

      </ul>
    </div>
  </nav>
  <div class="navv">
  <div class="row ortalayici " style="background-color: #0a0f1f ; height: 80px; ; width: 100%">
    <div class="navItem">
      <svg height="70" viewBox="0 0 288.93 154.35" width="150" xmlns="http://www.w3.org/2000/svg">
        <defs>

        </defs>
        <g id="Layer_1" data-name="Layer 1">
          <g>
            <path class="cls-1"
                  d="M261.21,66.74c-20.66-30.84-30.99-46.27-30.99-46.27,20.87,23.34,33.55,39.17,38.06,47.51,4.18,7.72,1.43,28.7-12.44,23.91-4.33.96-7.21-.18-7.91-4.58-1.41-8.74-18.66-14.45-25.91-15.02-8.1-.64-13.56-4.86-16.36-12.67-17.14,9.22-20.9,22.35-11.27,39.38,12.04,3.43,25.96,9.09,41.77,17,16.12,8.06,35.03,10.93,52.77,7.32-55.79,21.39-105.11-43.36-183.63-28.24,24.04-6.95,50.3-6.63,78.77.95-19.57-14.43-9.09-31.95,9.49-39.86-11.21,7.74-16.08,15.89-14.59,24.44,2.92-13.15,11.78-22.08,26.57-26.81-.63-3.46-.07-7.46,1.66-11.98-.49,21.36,9.64,30.76,30.61,24.91-4.59,2.77-6.88,4.15-6.88,4.15,6.81,1.06,11.71,5.19,17.64,7.76,4.44,1.93,8.5,1.71,10.47-4.56,6.97-.28,4.62-3.68,2.17-7.35h0Z"/>
            <path class="cls-1"
                  d="M227.36,21.83c3.65,6.56,4.42,14.34-2.3,19.09-6.29,4.44-9.75,10.18-10.4,17.21-1.78-7.95-.05-14.18,5.17-18.69,6.2-5.36,7.25-9.17,7.52-17.61h0Z"/>
            <path class="cls-1"
                  d="M232.1,8.72c-.27,2.63-1.54,5.65-3.79,9.07,6.02-3.6,8.31-9.06,6.88-16.37-7.2,7.04-10.79,10.56-10.79,10.56-1.24-3.12-.89-7.11,1.07-11.98-8.43,5.74-13.37,11.07-14.83,16.01-45.21-.61-83.05,19.52-113.52,60.38,25.54-1.22,49.03,2.34,70.46,10.68-17.06-10.16-34.06-15.7-51.01-16.61,19.58-24.39,47.89-38.7,84.94-42.94-15.62-1.04-28.86.54-39.74,4.74,14.7-7.04,30.91-10.76,48.64-11.15,0,3.33.43,5.58,1.3,6.76.07-7.8,3.39-14.04,9.96-18.74-.27,3.3-.35,5.72-.25,7.24.19,2.85,2.67,6.55-1.12,8.72,3.41-.97,4.89-4.71,4.45-11.21,2.07-2.06,4.52-3.78,7.36-5.16h0Z"/>
            <path class="cls-1"
                  d="M208.44,13.83c-7.56-6.81-20.69-7.88-39.37-3.21-11.71,2.92-24.68,4.92-35.84-1.06,7.26,6.56,17.41,8.77,30.46,6.62,14.51-2.38,30.15-6.27,44.75-2.35h0Z"/>
            <path class="cls-1" d="M173.08,7.18c-16.22,5.2-27.37,5.6-33.45,1.19,7.26,2.4,18.41,2.01,33.45-1.19h0Z"/>
            <path class="cls-1"
                  d="M138.32,21.18c-5.87-.29-13.39,1.44-22.56,5.21-4.49,1.84-10.89,1.89-15.16-.58,3.68,3.68,8.25,4.94,13.7,3.78,6.35-1.35,17.4-6.14,24.03-8.41h0Z"/>
            <path class="cls-1"
                  d="M162.4,21.9c-9.67-1.99-22.95.94-39.84,8.8-10.51,4.87-27.41-.49-36.91-5.72,9.35,9.88,21,14.2,34.94,12.96,4.49-.39,32.63-13.32,41.81-16.04h0Z"/>
            <path class="cls-1"
                  d="M131.68,35.66c-7.21,5.64-18.11,8.31-27.16,7.41-16.09-1.61-27.12,1.02-33.11,7.89,7.04-2.75,18.06-3.6,33.04-2.54,8.78.61,22.29-5.15,27.22-12.76h0Z"/>
            <path class="cls-1"
                  d="M113.41,52.98c-5.02,6.21-11.23,9.48-18.61,9.82-6.08.27-14.01-5-21.24-.33,5.45-4.78,12.8-6.39,22.03-4.85,6.46,1.08,12.17-1.67,17.83-4.64h0Z"/>
            <path class="cls-1"
                  d="M137.84,84.41c-32.48-7.71-46.93-3.07-75.83,9.9-21.02,9.43-41.7,14.52-62.01,15.25,19.65,4.26,41.58,1.49,65.76-8.32,27.06-10.97,40.42-20.65,72.08-16.83h0Z"/>
            <path class="cls-1"
                  d="M222.54,120.71c-29.64,4.12-59.54-11.8-87.49-18.39-46.78-11.06-43.17,31.59-21.88,28.48,5.83-.93,10.1-4.22,12.81-9.85-28.83,18.01-30.03-24.89,6.93-13.71,30.5,9.23,56.6,20.7,89.63,13.48h0Z"/>
            <path class="cls-1"
                  d="M185.88,125.93c-13.23,6.56-49.61-13.52-55.16,3.91,18.75-14.33,39.31,7.03,55.16-3.91h0Z"/>
            <path class="cls-1"
                  d="M171.64,135.54c9.94-6.45,26.03-7.11,48.28-1.98,15.26,3.52,30.77,1.75,45.79-2.17-13.24,6-28.06,7.97-44.47,5.91-19.48-2.44-28.89-6.16-49.6-1.76h0Z"/>
            <path class="cls-1"
                  d="M239.85,142.66c-19.54,3.3-34.04-3.72-51.73-4.9-7.63-.51-12.1,2.13-13.38,7.93-1.69,7.66,6.87,13.3,14.46,3.26-14.76,9.92-16.9-10.93,2.02-8.34,15.14,2.06,33.23,9.75,48.63,2.05h0Z"/>
          </g>
        </g>
      </svg>
    </div>
    <div class="navItem">
      <button class="baslık" @click="clickRouteMethod( '/')"> Startseite</button>
    </div>
    <div class="navItem">
      <button class="baslık" @click="clickRouteMethod( '/hakkimizda')"> Über uns</button>
    </div>

    <div class="navItem">
      <button class="baslık" @click="clickRouteMethod( '/contact')"> Kontakt</button>
    </div>
    <div class="navItem">
      <button class="baslık" @click="clickRouteMethod( '/hizmetlerimiz')"> Unsere Dienstleistungen</button>
    </div>
    <div class="navItem1">
      <a href="mailto:info@ankatedarik.com" target="_blank">
        <i class="fas fa-envelope navItem "></i>
      </a>
    </div>
    <div class="navItem1">
      <a href="https://www.google.com/maps/dir/39.9245312,32.8237056/Yeni+Batı,+2401.+Sk.+No:10,+06370+Yenimahalle%2FAnkara/@39.9433593,32.589958,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14d3364d993fb63b:0x10adcbcc312d295a!2m2!1d32.6854079!2d39.9778253?entry=ttu"
         target="_blank">
        <i class="fas fa-map-marker-alt navItem "></i>
      </a>
    </div>
    <div class="navItem1">
      <a href="tel:+905422572866" target="_blank">
        <i class="fas fa-phone navItem  "></i>
      </a>
    </div>

  </div>
  </div>

</template>
<script>
import {ref} from "vue";

export default {
  name: 'navbarPage',
  components: {},
  methods: {
    clickRouteMethod(route) {
      this.$router.push(route);
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
  },
  setup() {
    const collapse7 = ref(false);

    return {
      collapse7,
    }
  }
};
</script>
<style>
@media (max-width: 1191px) {
  .navv {
    display: none;
  }
  .openClose {
    display: block;
  }
}

@media (min-width: 1192px) {
  .openClose {
    display: none;
  }
}

.cls-1 {
  fill: #ffffff;
  fill-rule: evenodd;
  stroke-width: 0px;
}

#menuToggle {
  display: block;
  position: relative;
  top: 35px;


  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}


#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  font-weight:bold;
  position: absolute;
  width: 300px;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 70px;

  background: #ffffff;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 10px 0;
  font-size: 30px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
}

.baslık {

  margin-left: 0px;
color:white;
background-color:transparent;
  border-radius: 0px;
  border: none; /* Butonun çerçevesini kaldırır */
  padding: 0; /* Butonun iç boşluğunu kaldırır */
  outline: none; /* Butonun kenarlığını kaldırır */
  font-weight: bold;
  font-size: 70%;
  font-family: Poppins-Regular,Arial
}

.light {

  margin-left: 20px;
  margin-right: 10px;
}

.at {
  margin-top: 10px;
}

.itemler {
  margin-left: 1vw;
}

.fas.fa-phone.navItem {

  color: white;
  font-size: 20px;


}

.navItem {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  font-size: 36px;
  width: 15%;
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.navItem1 {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;

  width: 3%;
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease;
}


.fas.fa-envelope.navItem {

  color: white;
  font-size: 20px;
}

.navbarItem {
  margin-right: 20px;
  margin-left: 10px
}

.fas.fa-map-marker-alt.navItem {

  color: white;
  font-size: 20px;
}

.photoNavFirst {
  display: flex;
  align-items: start;
  justify-content: start;
}
</style>